import React, { useState, useEffect } from "react";
import './App.css';
import logo from './img/ica-logo.png';
import FormularioCliente from './components/FormularioCliente';
import LoginPage from './components/LoginPage';
import FormMyList from "./components/form/FormMyList";
import FormDetail from "./components/form/FormDetail";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAuth } from '../src/components/AuthProvider';
import PurchaseRequestForm from "./components/PurchaseRequestForm";
function App() {
  const { user, isLoading, isLoggedIn, getCurrentUser } = useAuth();

  useEffect(() => {
    if (!user) {
      getCurrentUser();
    }
  }, [user, getCurrentUser]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: user ?
      // <PurchaseRequestForm/>: <LoginPage />,
        <FormMyList user={user} /> : <LoginPage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/mis-solicitudes",
      element: <FormMyList user={user} />,
    },
    {
      path: "/nueva-solicitud",
      element: <FormularioCliente user={user} />,
    },
    {
      path: "/mis-solicitudes/:id",
      element: <FormDetail />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} user={user} />
    </div>
  );
}

export default App;
