import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './drop-file-input.css';

import { ImageConfig } from '../../config/ImageConfig';
import uploadImg from '../../assets/cloud-upload-regular-240.png';

const DropRetrieveFile = (props) => {
  const { fieldName, onFileChange, title, fileList } = props;

  const wrapperRef = useRef(null);

  const [file, setFile] = useState(null);
  useEffect(() => {
    // Si `fileList` contiene una URL de archivo del servidor, establece `file` para mostrar la vista previa.
    if (fileList &&  fileList.filename_download) {
        const oldFile = fileList;
        const {id,type, filename_download } = fileList;
      setFile({
        name: filename_download, // Puedes personalizar el nombre como desees
        type: type, // Añade el tipo MIME si lo tienes
        url: "https://api2.icautomatizados.com/assets/"+id, // La URL del archivo en el servidor
      });
      onFileChange(filename_download, oldFile); // Pasa el fieldName junto con el archivo

    }
  }, [fileList]);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFile(newFile);
      onFileChange(fieldName, newFile); // Pasa el fieldName junto con el archivo
    }
  };

  const fileRemove = () => {
    setFile(null);
    onFileChange(fieldName, null); // Pasa el fieldName junto con null para indicar que se eliminó el archivo
  };

  return (
    <div className='container_attachments'>
      <h5>{title}</h5>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Arrastra y suelta tu archivo</p>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {file ? (
        <div className="drop-file-preview">
          <div className="drop-file-preview__item">
            {file.url ? (
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={
                    ImageConfig[file.type.split('/')[1]] ||
                    ImageConfig['default']
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <p>{file.name}</p>
                </div>
              </a>
            ) : (
              <>
                <img
                  src={
                    ImageConfig[file.type.split('/')[1]] ||
                    ImageConfig['default']
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <p>{file.name}</p>
                </div>
              </>
            )}
            <span
              className="drop-file-preview__item__del"
              onClick={fileRemove}
            >
              x
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

DropRetrieveFile.propTypes = {
  fieldName: PropTypes.string.isRequired, // Propiedad obligatoria para identificar el campo del formulario
  onFileChange: PropTypes.func.isRequired, // Propiedad obligatoria para manejar el cambio de archivos
  title: PropTypes.string.isRequired, // Título del campo
  fileList: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Puede ser una URL o un objeto de archivo
};

export default DropRetrieveFile;
