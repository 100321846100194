import { Directus } from "@directus/sdk";

export const directus = new Directus('https://api2.icautomatizados.com/');

export async function getPurchaseRequest(userId, role) {

    if(role === 'f5c76108-b4dd-4623-9541-9c409434d84c'){
        
        const data = await directus.items("purchase_requests").readByQuery({
            fields:["id","date_created","date_updated","comercial","cliente_id", "client_name", "ov_id"],
            status: "published",
            sort: "-id" // Sort in descending order by the "id" field

        });
        return data
    }
    const data = await directus.items("purchase_requests").readByQuery({
        fields:["id","date_created","date_updated","comercial","cliente_id", "client_name", "ov_id"],
        filter: { comercial: { _eq: userId } },
        sort: "-id", // Sort in descending order by the "id" field,
        status: "published",
    });

    return data;
}
export async function searchPurchaseRequest(userId,searchString, role) {
    const data = await directus.items("purchase_requests").readByQuery({
        filter: { ov_id: { _eq: searchString } },
        })
        if(role !== 'f5c76108-b4dd-4623-9541-9c409434d84c'){
            return data.data
        }
       const filterData= data.data.filter((p) => p.user_created === userId)
    return filterData;
}
export async function getPurchaseCount() {
    const data = await directus.items("purchase_requests").readByQuery({
        fields: ["id"],
        limit: 1, 
        sort: "-id" // Sort in descending order by the "id" field
      });
      return data.data[0].id;; 
    }

export async function retrieveFile(id) {
    const data = await directus.files.readOne(id)
    return data;
}

export async function retrieveManyFile(id) {
    const list=[]

    for (let i=0; i<id.length; i++){
        const data = await directus.files.readOne(id[i])
        list.push(data)
    }
    return list;
        
}
export async function getSinglePurchaseRequest(id) {
    const data = await directus.items("purchase_requests").readOne(id);

    return data;
}
export async function createPurchaseRequest(request) {
    const data = await directus.items("purchase_requests").createOne(request).then((res) => res.data);
    return data;
}

export async function updatePurchaseRequest(request) {
    console.log(request)
    const data = await directus.items("purchase_requests").updateOne(request.id, request)
    return data;
}

export async function getClients() {
    const data = await directus.items("clients").readByQuery({
        fields: ["id","client_id", "client_name"],
        limit: -1
    });
    return data.data;
}
export async function uploadFiles(file) {
        const data= await directus.files.createOne(file).then(data=> data);
        return data
    }
    export async function uploaManydFiles(file) {
        const data= await directus.files.createMany(file).then(data=> data);
        return data
    }

    export async function refreshToken() {
        try {
        const data =  await directus.auth.refresh();
        return data
        } catch (error) {
          console.error('Error refreshing token:', error);
          throw error; // You might want to handle this error accordingly
        }
      }

    export async function login(email, password) {
        try {
            const data = await directus.auth.login({ email, password })
            .then(async (res) => {
             const {access_token ,expires} = res 
            const user = await directus.users.me.read(access_token,{ limit: -1})
            return { user, access_token, expires };
        } )
        return data
    } catch (error) {
        if (error.message === "Invalid user credentials.") {
            console.error('Invalid user credentials:', error);
        } else {
            console.error('Error during login:', error);
        }
        return null; 
    }}

    export async function logout() {
        const data = await directus.auth.logout()
        return data;
    }
//     export async function createPurchaseRequest(data, onUploadProgress) {
//         const result = await directus.files.createOne(data, onUploadProgress);
//         return result;
//     }
