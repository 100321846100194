import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './drop-file-input.css';
import { ImageConfig } from '../../config/ImageConfig';
import uploadImg from '../../assets/cloud-upload-regular-240.png';

const DropRetrieveFile = (props) => {
  const { fieldName, onFileChange, title, fileList } = props;

  const wrapperRef = useRef(null);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    

    if (fileList && Array.isArray(fileList)) {
        console.log('ready')
        const oldFile = [];
        for (let i=0; i < fileList.length; i++){
            if(fileList[i].type ){

                const {id,type, filename_download } = fileList[i];
                
                const newFile={
                    name: filename_download, // Puedes personalizar el nombre como desees
                    type: type, // Añade el tipo MIME si lo tienes
                    url: "https://api2.icautomatizados.com/assets/"+id, // La URL del archivo en el servidor
                };
                
                oldFile.push(newFile);
            }
            
        }
        setFiles(oldFile);
        onFileChange(files,oldFile); // Pass the fieldName along with the updated array of files
    };
            
        
    
  }, [fileList]);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedFiles = [...files, newFile];
      setFiles(updatedFiles);
      onFileChange(fieldName, updatedFiles); // Pass the fieldName along with the updated array of files
    }
  };

  const fileRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    onFileChange(fieldName, updatedFiles); // Pass the fieldName along with the updated array of files
  };

  return (
    <div className='container_attachments'>
      <h5>{title}</h5>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Arrastra y suelta tus archivos</p>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {files.length > 0 ? (
        <div className="drop-file-preview">
          {files.map((file, index) => (
            <div key={index} className="drop-file-preview__item">
              <a href={file.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={
                    ImageConfig[file.type.split('/')[1]] || ImageConfig['default']
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <p>{file.name}</p>
                </div>
              </a>
              <span
                className="drop-file-preview__item__del"
                onClick={() => fileRemove(index)}
              >
                x
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

DropRetrieveFile.propTypes = {
  fieldName: PropTypes.string.isRequired, // Mandatory property to identify the form field
  onFileChange: PropTypes.func.isRequired, // Mandatory property to handle file changes
  title: PropTypes.string.isRequired, // Field title
  fileList: PropTypes.array, // An array of file objects
};

export default DropRetrieveFile;
