import React, { useState, useEffect } from 'react';
import { getPurchaseRequest, searchPurchaseRequest } from '../../store/directusSdk';
import './formlist.css';
import TableRow from './TableRow';
import NavigationMenu from '../nav/NavigationMenu';
import { useNavigate, Link } from 'react-router-dom';

export default function FormMyList({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [purchaseRequest, setPurchaseRequest] = useState([]);
  const [purchaseRequestList, setPurchaseRequestList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  let navigate = useNavigate();
  const { id, role, first_name, last_name } = user.user;
  const comercial = first_name + ' ' + last_name;

  useEffect(() => {
    getPurchaseRequest(comercial, role).then((res) => {
      setPurchaseRequest(res.data);
      setPurchaseRequestList(res.data);
    });
  }, []);

  useEffect(() => {
    if (searchValue.length > 2) {
      const filter = purchaseRequest.filter(
        (p) =>
          p.client_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          p.ov_id.toString().includes(searchValue)
      );

      setSearchResults(filter);
      setPurchaseRequestList(filter);
    }

    if (searchValue.length === 0) {
      setSearchResults([]);
      setPurchaseRequestList(purchaseRequest);
    }
  }, [searchValue]);

  const handleEditar = (item) => {
    navigate('/mis-solicitudes/' + item.id);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    if (searchQuery === '') {
      setSearchValue('');
    } else {
      setSearchValue(searchQuery);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedItems = purchaseRequestList.sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else if (sortConfig.direction === 'desc') {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    const totalPages = Math.ceil(sortedItems.length / itemsPerPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  function formatDate(fechaString) {
    const fecha = new Date(fechaString);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const anio = fecha.getFullYear();
    return `${dia}/${mes}/${anio}`;
  }
  const findMostFrequentClient = () => {
    if(purchaseRequestList.length > 0){

    const clientCount = purchaseRequestList.length > 0 && purchaseRequestList?.reduce((acc, purchaseRequest) => {
      const client = purchaseRequest.client_name;
      acc[client] = (acc[client] || 0) + 1;
      return acc;
    }, {});
  
    const mostFrequentClient = Object.keys(clientCount).reduce((a, b) =>
      clientCount[a] > clientCount[b] ? a : b
    );
  
    return mostFrequentClient;
  };
}
  const mostFrequentClient = findMostFrequentClient();
  return (
    <>
      {user && (
        <div className='main'>
          <NavigationMenu isOpen={isOpen} toggleMenu={toggleMenu} />
          <div className='container-table'>
              <div className='title'>
                <h1>Mis Solicitudes de Compras</h1>
                <h4 className='welcome'>¡Bienvenido {first_name} {last_name}!</h4>
              </div>
                  <div className='options-header'>
                    <div className='search-input'>
                      <span>Buscar </span>
                      <input type='search' placeholder='Buscar por Cliente o OV' onChange={handleSearch} />
                    </div>
                   <div className='header-second'>
                    <div className='menu-top'>
                      <span> <b>{purchaseRequestList.length}</b> </span><span> solicitudes</span>
                    </div>
                    <div className='menu-top'>
                    <span> <b>{mostFrequentClient}</b></span>  <span>TOP cliente </span>                
                      </div>
                   </div>
                  </div>
            <div className='table'>
              {purchaseRequestList.length > 0 ? (
                <div>
                  <div className='row header'>
                    <div
                      className={isOpen ? 'menu-open' : ''}
                      onClick={() => handleSort('date_created')}
                    >
                      ↓ Fecha de creación ↓
                      {sortConfig.key === 'date_created' && (
                        <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                      )}
                    </div>
                    {/* Add similar onClick and conditional rendering for other header columns */}
                    <div className={isOpen ? 'menu-open' : ''} onClick={() => handleSort('ov_id')}>
                    ↑ OV ↓
                      {sortConfig.key === 'ov_id' && <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                    </div>
                    <div className={isOpen ? 'menu-open' : ''} onClick={() => handleSort('client_name')}>
                    ↑ Cliente ↓
                      {sortConfig.key === 'client_name' && (
                        <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                      )}
                    </div>
                    {/* Add similar onClick and conditional rendering for other header columns */}
                    <div className={isOpen ? 'menu-open' : ''} onClick={() => handleSort('comercial')}>
                    ↑ Solicitante ↓
                      {sortConfig.key === 'comercial' && (
                        <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                      )}
                    </div>
                    <div className={isOpen ? 'menu-open' : ''} onClick={() => handleSort('date_updated')}>
                    ↑ Fecha de modificación ↓
                      {sortConfig.key === 'date_updated' && (
                        <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                      )}
                    </div>
                    <div>Acciones </div>
                  </div>
                  <div className='row-items'>
                    {currentItems.map((item, index) => (
                      <div key={index} className={`row body ${index % 2 === 0 ? 'light' : 'dark'}`}>
                        <div className= 'row-item'>{formatDate(item.date_created)}</div>
                        <div className= 'row-item'>{item.ov_id}</div>
                        <div className= 'row-item'>{item.client_name}</div>
                        <div className= 'row-item'>{item.comercial}</div>
                        <div className= 'row-item'>
                          {item.date_updated ? formatDate(item.date_updated) : 'Sin Modificaciones'}
                        </div>
                        <button className='update_button' onClick={() => handleEditar(item)}>
                          <i className='material-icons'>editar</i>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className='container-null'>
                  <h2>No tienes solicitudes aún</h2>
                  <button className='create-new-button' onClick={() => navigate('/nueva-solicitud')}>
                    Crear Solicitud
                  </button>
                </div>
              )}
              <div className='options-container'>
                <div className='options'>
                  <span className={isOpen ? 'menu-open-search' : ''}>{`Mostrando ${currentItems.length} de ${
                    purchaseRequestList.length
                  }`}</span>
                  <div>
                    <i className='fas fa-caret-left' onClick={() => paginate(currentPage - 1)}></i>
                    <span className='page-active'>{currentPage}</span>
                    <i className='fas fa-caret-right' onClick={() => paginate(currentPage + 1)}></i>
                  </div>
                  <span>{`Página ${currentPage} de ${Math.ceil(sortedItems.length / itemsPerPage)}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}