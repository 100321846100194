import "./LoginPage.css";
import logo from '../img/ICONO BLANCO.png';
import { login, logout } from '../store/directusSdk';
import {useState} from "react";
import { useNavigate  } from "react-router-dom";
import { useAuth } from '../components/AuthProvider';

function LoginPage() {
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mensaje, setMensaje] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      await login(email, password);
      // Handle successful login, you can also navigate the user to another page
    } catch (error) {
      setMensaje('Usuario o contraseña incorrectos');
    } finally {
      setIsLoading(false);
    }
  };

  return (
 <div className="login_page_container">

    <div className="login-page">
      <img className='logo' height={120} width={120} src={logo} alt='logo ICAUTOMATIZADOS' />
      <h1 className="title_login">Iniciar sesión</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="title_login" htmlFor="email">Correo electrónico:</label>
          <input className="form-control"
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="correo@dominio.com"
          />
        </div>
        <div className="form-group">
          <label className="title_login" htmlFor="password">Contraseña:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Contraseña"
          />
        </div>
        {mensaje && <p className="mensaje_error">{mensaje}</p>}

        <button className="button_submit" type="submit">Iniciar sesión</button>
      </form>
    </div>
    </div>
  );
}

export default LoginPage;