import React from 'react'

export default function ItemFormulario({ type, title, name, value, handleInputChange, placeholder, required }) {
  return (
    <div>
      {type === 'attachment' ?
        <div className='item_container'>
          <h5>{title}</h5>
          <input
            className='input-field'
            type='file'
            accept='image/*,.pdf,.doc,.docx'
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            required={required}
          />
        </div>
    : type === 'text' ?
    <div>
      <div className='item_container'>
              <h5>{title}</h5>
              <textarea
                className='input-field'
                type='text'
                id={name}
                name={name}
                value={value}
                onChange={handleInputChange}
                placeholder={placeholder}
                />
            </div>
      
      </div>:
      <div className='item_container'>
              <h5>{title}</h5>
          <input
            className='input-field'
            type='file'
            accept='image/*,.pdf,.doc,.docx'
            id={name}
            name={name}
            value={value}
            onChange={handleInputChange}
            multiple
          />
            </div>
}
    </div>)}

