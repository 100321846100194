// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { login as apiLogin, logout as apiLogout, refreshToken } from '../store/directusSdk';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = async (email, password) => {
    setIsLoading(true);
    try {
      const res = await apiLogin(email, password);
      setUser(res);
      localStorage.setItem('user', JSON.stringify(res));
      setIsLoggedIn(true);
      setIsLoading(false);
      return res;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  const logout = async () => {
    await apiLogout();
    localStorage.removeItem('user');
    setUser(null);
    setIsLoggedIn(false);
  };

  const getCurrentUser = async() => {
   
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setIsLoading(false);
      setIsLoggedIn(true);
    } else {
      setIsLoading(false);
      setIsLoggedIn(false);
    }
  };

  const contextValue = {
    user,
    isLoading,
    isLoggedIn,
    login,
    logout,
    getCurrentUser,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
