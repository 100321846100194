import ReactModal from 'react-modal';

export default function Modal({ isOpen, onRequestClose }) {
  return (
    <ReactModal
    isOpen={isOpen}
    ariaHideApp={false}
    onRequestClose={onRequestClose}
    contentLabel="Confirmación"
    style={{
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
      content: {
        width: "350px",
        height: "250px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      },
    }}
  >
      <div className='confirmacion_container'>
      <h1>¡Gracias!</h1>
      <h4 className="texto_confirmacion">
        Tu Solicitud de compra se envió exitosamente.
      </h4>
      <button className='button_form' onClick={onRequestClose}>Aceptar</button>
    </div>
  </ReactModal>
  );
}