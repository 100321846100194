// DropSingleFileInput.js
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './drop-file-input.css';

import { ImageConfig } from '../../config/ImageConfig';
import uploadImg from '../../assets/cloud-upload-regular-240.png';

const DropSingleFileInput = (props) => {
  const { fieldName, onFileChange, title } = props;

  const wrapperRef = useRef(null);

  const [file, setFile] = useState(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFile(newFile);
      onFileChange(fieldName, newFile); // Pasa el fieldName junto con el archivo
    }
  };

  const fileRemove = () => {
    setFile(null);
    onFileChange(fieldName, null); // Pasa el fieldName junto con null para indicar que se eliminó el archivo
  };

  return (
    <div className='container_attachments'>
    <h5>{title}</h5>
    <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Arrastra y suelta tu archivo</p>
        </div>
        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {file ? (
        <div className="drop-file-preview">
          <div className="drop-file-preview__item">
            <img
              src={
                ImageConfig[file.type.split('/')[1]] || ImageConfig['default']
              }
              alt=""
            />
            <div className="drop-file-preview__item__info">
              <p>{file.name}</p>
            </div>
            <span
              className="drop-file-preview__item__del"
              onClick={fileRemove}
            >
              x
            </span>
          </div>
        </div>
      ) : null}
 </div>
  );
  
};

DropSingleFileInput.propTypes = {
  fieldName: PropTypes.string.isRequired, // Propiedad obligatoria para identificar el campo del formulario
  onFileChange: PropTypes.func.isRequired, // Propiedad obligatoria para manejar el cambio de archivos
};

export default DropSingleFileInput;
