import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../img/LOGO COMPLETO BLANCO.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faList,
  faPlus,
  faBars,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./NavigationMenu.css"; // Estilos personalizados

const NavigationMenu = ( {isOpen, toggleMenu}) => {
const logOut=()=>{
  localStorage.removeItem("user");
}


  return (
    <div className={`navigation-menu ${isOpen ? "open" : ""}` }  onClick={toggleMenu}>
      <div className="menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>
      <div className="menu-items">

        <Link to="/" className="menu-item">
          <FontAwesomeIcon icon={faHome} />
          <span className={isOpen ? "show" : "hide"}>Home</span>        
          </Link>
        <Link to="/mis-solicitudes" className="menu-item">
          <FontAwesomeIcon icon={faList} />
          <span className={isOpen ? "show" : "hide"}>Mis Solicitudes</span>
                  </Link>
        <Link to="/nueva-solicitud" className="menu-item">
          <FontAwesomeIcon icon={faPlus} />
          <span className={isOpen ? "show" : "hide"}>Nueva Solicitud</span>
        </Link>
        <a href="/" className="menu-item" onClick={logOut}>
          <FontAwesomeIcon icon={faSignOutAlt} />
          <span className={isOpen ? "show" : "hide"} onClick={logOut}>Salir</span>        
          </a>

      </div>
    </div>
  );
};

export default NavigationMenu;
