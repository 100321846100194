import React, { useEffect, useState, useCallback } from 'react';
import ItemFormulario from '../Input'
import '../../components/styles.css';
import ReactModal from 'react-modal';
import { retrieveManyFile, retrieveFile, getSinglePurchaseRequest, getPurchaseRequest, createPurchaseRequest, getClients, uploadFiles, getPurchaseCount, logout, updatePurchaseRequest } from '../../store/directusSdk';
import DropFileInput from '../drop-file-input/DropFileInput'
import DropSingleFileInput from '../drop-file-input/DropSingleFileInput'
import Modal from '../modal';
import NavigationMenu from '../nav/NavigationMenu';
import logo from '../../img/ica-logo.png';
import DropRetrieveFile from '../drop-file-input/DropRetrieveFile';
import DropRetrieveFiles from '../drop-file-input/DropRetrieveFiles';
import { useNavigate, Link  } from "react-router-dom";

import { useParams } from 'react-router-dom';

export default function FormDetail() {
  const [isSend, setisSend] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [loadinSubmit, setLoadinSubmit] = useState(false)
  const [formData, setFormData] = useState([]);
  const [formFiles, setFormFiles] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [mensajePopUp, setMensajePopUp] = useState([]);
  const [purchase_request_attachment, setPurchase_request_attachment] = useState(null);
  const [clients, setClients] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(0)
  const [CurrentUser, SetCurrentUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams();
  let navigate = useNavigate();

  const initial_state = {
    cliente_id: '',
    client_name: null,
    ov_id: null,
    purchase_request_attachment: null,
    purchase_request_coments: '',
    purchase_order_attachment: null,
    comments_for_invoicing: '',
    quotation_or_proposal_attachment: null,
    comments_for_customer_service: '',
    sales_order_attachment: null,
    comments_for_sales_order: '',
    budget_attachment: null,
    comments_for_administration: '',
    comments_for_eng: '',
    quotation_provider_attachment: [],
    quotation_log_provider_attachment: [],
    comments_for_purchase: '',
    comments_for_log: '',
    comercial: '',
  };

  useEffect(() => {
    getSinglePurchaseRequest(id).then((res) => {
      retrieveFile(res.purchase_request_attachment).then((res) => {
        setFormFiles(prev => ({ ...prev, purchase_request_attachment: res }))
        setFormData(prev => ({ ...prev, purchase_request_attachment: res.id }))

      })
      retrieveFile(res.purchase_order_attachment).then((res) => {
        setFormFiles(prev => ({ ...prev, purchase_order_attachment: res }))
        setFormData(prev => ({ ...prev, purchase_order_attachment: res.id }))
      })
      retrieveFile(res.quotation_or_proposal_attachment).then((res) => {
        setFormFiles(prev => ({ ...prev, quotation_or_proposal_attachment: res }))
        setFormData(prev => ({ ...prev, quotation_or_proposal_attachment: res.id }))
      })
      retrieveFile(res.purchase_request_attachment).then((res) => {
        setFormFiles(prev => ({ ...prev, purchase_request_attachment: res }))
        setFormData(prev => ({ ...prev, purchase_request_attachment: res.id }))
      })
      retrieveFile(res.sales_order_attachment).then((res) => {
        setFormFiles(prev => ({ ...prev, sales_order_attachment: res }))
        setFormData(prev => ({ ...prev, sales_order_attachment: res.id }))
      })
      retrieveFile(res.budget_attachment).then((res) => {
        setFormFiles(prev => ({ ...prev, budget_attachment: res }))
        setFormData(prev => ({ ...prev, budget_attachment: res.id }))
      })


      const extractedParts = [];
      const extractedPartslog = [];
      if (res.quotation_provider_attachment.length > 0) {
        for (let i = 0; i < res.quotation_provider_attachment.length; i++) {
          const url = res.quotation_provider_attachment[i];
          const extractedPart = url.substr(url.length - 36);
          extractedParts.push(extractedPart);
        }
        retrieveManyFile(extractedParts).then((res) => {
          setFormData(prev => ({ ...prev, quotation_provider_attachment: res.id }))
          setFormFiles(prev => ({ ...prev, quotation_provider_attachment: res }))

        })

      }
      if (res.quotation_log_provider_attachment.length > 0) {

        for (let i = 0; i < res.quotation_log_provider_attachment.length; i++) {
          const url = res.quotation_log_provider_attachment[i];
          const extractedPart = url.substr(url.length - 36);
          extractedPartslog.push(extractedPart);
        }
        retrieveManyFile(extractedPartslog).then((res) => {
          setFormData(prev => ({ ...prev, quotation_log_provider_attachment: res.id }))
          setFormFiles(prev => ({ ...prev, quotation_log_provider_attachment: res }))

        })
      }
      setFormData(res)

    })
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.client_name === null && formData.ov_id === null && formData.purchase_request_attachment === null && formData.sales_order_attachment === null && formData.quotation_or_proposal_attachment === null) {
      setMensajePopUp(prev => [...prev, 'Cliente', 'OV', 'Solicitud de Compra', 'Orden de Venta', 'Cotización o Propuesta'])
      setShowModal(true)

    }
    else if (formData.ov_id === null && formData.purchase_request_attachment === null && formData.sales_order_attachment === null && formData.quotation_or_proposal_attachment === null) {

      setMensajePopUp(prev => [...prev, 'OV', 'Solicitud de Compra', 'orden de venta', 'cotizacion o propuesta'])
      setShowModal(true)
    }
    else if (formData.purchase_request_attachment === null && formData.sales_order_attachment === null && formData.quotation_or_proposal_attachment === null) {
      setMensajePopUp(prev => [...prev, 'Solicitud de Compra', 'orden de venta', 'cotizacion o propuesta'])
      setShowModal(true)
    }

    else if (formData.sales_order_attachment === null && formData.quotation_or_proposal_attachment === null) {
      setMensajePopUp(prev => [...prev, 'orden de venta', 'cotizacion o propuesta'])
      setShowModal(true)

    }
    else if (formData.quotation_or_proposal_attachment === null) {
      setMensajePopUp(prev => [...prev, 'cotizacion o propuesta'])
      setShowModal(true)

    } else {
      // Prepara un objeto que contendrá solo los IDs de los archivos seleccionados

      // Filtra solo los campos que contienen archivos o arrays de archivos
      const fileFieldNames = Object.keys(formData).filter((fieldName) => {
        const fieldData = formData[fieldName];
        return (
          fieldData instanceof File ||
          (Array.isArray(fieldData) && fieldData.every((item) => item instanceof File))
        );
      });
      setLoadinSubmit(true)
      // Utiliza Promise.all para esperar todas las promesas de carga de archivos
      await Promise.all(
        fileFieldNames.map(async (fieldName) => {
          const fieldData = formData[fieldName];

          if (Array.isArray(fieldData)) {
            // Si es un array de archivos
            const idArray = await Promise.all(
              fieldData.map(async (file) => {
                if (file instanceof File) {
                  // Sube el archivo y obtiene su ID solo si es de tipo File
                  const form = new FormData();
                  form.append(fieldName, file);
                  const response = await uploadFiles(form);
                  return `https://api2.icautomatizados.com/assets/${response.id}`;
                } else {
                  // Conserva el valor original (puede ser un ID ya cargado)
                  return file;
                }
              })
            );
            formData[fieldName] = idArray;
          } else if (fieldData instanceof File) {
            // Si es un solo archivo
            const form = new FormData();
            form.append(fieldName, fieldData);
            const response = await uploadFiles(form);
            formData[fieldName] = response.id;
          }
        })
      );

      try {
        // Actualiza el estado con los IDs recibidos
        updatePurchaseRequest(formData).then(() => {
          setLoadinSubmit(false)
          setisSend(true)
          setFormData(initial_state)})
          setFormFiles([])
      } catch (error) {
        // Maneja errores de carga
        console.error('Error al cargar archivos:', error);
      }
    };
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 200, // establece el ancho deseado
    }),
  };

  const handleSingleFileChange = (fieldName, newFile) => {
    // Actualiza el estado local con el archivo seleccionado o eliminado
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: newFile,
    }));
  };

  const handleFileChange = (fieldName, newFileList) => {
    // Actualiza el estado local con los archivos seleccionados
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: newFileList,
    }));
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className='main'>
      <NavigationMenu isOpen={isOpen} toggleMenu={toggleMenu} />
        <div className='formularioContainer'>
          <header className='formularioHeader'>
            <img className='logo' height={80} width={180} src={logo} alt='logo ICAUTOMATIZADOS' />
          </header>
          <ReactModal className='modal'
            ariaHideApp={false}
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            contentLabel="Ejemplo de popup"
          >
            <h2>Falta Información 🧐</h2>
            {mensajePopUp.map((item, index) => (
              <p key={index}>El campo <b className='item_not_found'>{item}</b> es obligatorio.</p>
            ))}
            <button className='button_form' onClick={() => {
              setMensajePopUp([])
              setShowModal(false)
            }
            }>Aceptar</button>
          </ReactModal>
          {loadinSubmit ? <div className='confirmacion_container'>
            <h1>¡Tu Solicitud se esta enviando!</h1>
            <div className="loader"></div>
            <p className='texto_confirmacion'>subiendo archivos ...</p>
          </div> :
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <h2>SOLICITUD DE COMPRA #{formData.id}</h2>
              <div className='header_request'>
                <h5>Nombre de cliente:</h5>
                <input type='text' value={formData.client_name} readOnly={true} />
                <h5>Número de OV:</h5>
                <input type='text' value={formData.ov_id} readOnly={true} />

              </div>
              <div>
                <h3 className='title_container'>SOLICITUD DE COMPRA</h3>
                <DropRetrieveFile fieldName="purchase_request_attachment" onFileChange={handleSingleFileChange} fileList={formFiles.purchase_request_attachment} title='Adjunte Solicitud de compra' />
                <ItemFormulario type='text' title='Comentarios para Compras' name='purchase_request_coments' handleInputChange={handleInputChange} value={formData.purchase_request_coments}
                  placeholder='Comentarios para compras, indicando los aspectos relevantes de esta solicitud de manera general, lo que no se debe comprar porque está en stock o cuándo debe generarse la compra del servicio contratado.' />
                <h3 className='title_container'>ORDEN DE COMPRA</h3>
                <DropRetrieveFile fieldName="purchase_order_attachment" onFileChange={handleSingleFileChange} fileList={formFiles.purchase_order_attachment} title='Adjunte Orden de Compra del Cliente' />
                <ItemFormulario type='text' title='Comentarios para Facturación' name='comments_for_invoicing' value={formData.comments_for_invoicing} handleInputChange={handleInputChange}
                  placeholder='Comentarios para facturación con respecto a cuando y como deben realizarse las facturas de esta OV según lo negociado en la propuesta.' />
                <h3 className='title_container'>COTIZACIÓN O PROPUESTA</h3>
                <DropRetrieveFile fieldName="quotation_or_proposal_attachment" onFileChange={handleSingleFileChange} fileList={formFiles.quotation_or_proposal_attachment} title='Adjunte Cotización o Propuesta' />
                <ItemFormulario type='text' title='Comentarios para Servicio Al cliente' name='comments_for_customer_service' value={formData.comments_for_customer_service} handleInputChange={handleInputChange}
                  placeholder='Comentarios acerca de las fechas de entrega y las expectativas del cliente en cuanto al servicio que ICA debe proporcionar.' />
                <h3 className='title_container'>ORDEN DE VENTA</h3>
                <DropRetrieveFile fieldName="sales_order_attachment" onFileChange={handleSingleFileChange} fileList={formFiles.sales_order_attachment} title='Adjunte Orden de Venta' />
                <ItemFormulario type='text' title='Comentarios generales sobre la OV' name='comments_for_sales_order' value={formData.comments_for_sales_order} handleInputChange={handleInputChange}
                  placeholder='Comentarios sobre la entrega pactada, incoterms de entrega, fechas de entrega u otros aspectos de la entrega al cliente de esta orden de venta.' />
                <h3 className='title_container'>PRESUPUESTO</h3>
                <DropRetrieveFile fieldName="budget_attachment" onFileChange={handleSingleFileChange} fileList={formFiles.budget_attachment} title='Adjunte Presupuesto' />
                <ItemFormulario type='text' title='Comentarios para Administración' name='comments_for_administration' value={formData.comments_for_administration} handleInputChange={handleInputChange}
                  placeholder='Comentarios para la Administración respecto al presupuesto, indicando cuándo y cómo se espera recibir los pagos, así como la existencia de anticipos u otros métodos de cobro.' />
                <ItemFormulario type='text' title='Comentarios para Ingeniería' name='comments_for_eng' value={formData.comments_for_eng} handleInputChange={handleInputChange}
                  placeholder='Comentarios para planeación de Ingeniería o aspectos técnicos de servicio prometido en esta OV.' />

                <h3 className='title_container'>COTIZACIÓN DEL PROVEEDOR</h3>
                <DropRetrieveFiles fieldName="quotation_provider_attachment" onFileChange={handleFileChange} fileList={formFiles.quotation_provider_attachment && formFiles.quotation_provider_attachment} title='Adjunte Cotización del proveedor' />
                <DropRetrieveFiles fieldName="quotation_log_provider_attachment" onFileChange={handleFileChange} fileList={formFiles.quotation_log_provider_attachment && formFiles.quotation_log_provider_attachment} title='Adjunte Cotización del proveedor logístico' />
                <ItemFormulario type='text' title='Comentarios para Compras' name='comments_for_purchase' value={formData.comments_for_purchase} handleInputChange={handleInputChange}
                  placeholder='Comentarios para compras con respecto a lo negociado con los proveedores, como formas de pago, tiempos de entrega esperados, y otros sobre las cotizaciones de los proveedores.' />
                <ItemFormulario type='text' title='Comentarios para Logística' name='comments_for_log' value={formData.comments_for_log} handleInputChange={handleInputChange}
                  placeholder='Comentarios para logística sobre el incoterm de entrega de los provedores, los tiempos de entrega y si se requiere alguna gestión de logistica en las compras a realizar.' />
              </div>
              <button className='button_form' >Actualizar</button>
            </form>
          }
          <Modal className='modal'
            ariaHideApp={false}
            isOpen={isSend}
            onRequestClose={() => {
              setisSend(false)
              navigate("/mis-solicitudes");
            }
            }
            contentLabel="popup"
          />
          {/* <div className='confirmacion_container'>
        <h1>¡Gracias!</h1>
        <Confetti />
        <p className='texto_confirmacion'>Tu Solicitud se envió exitosamente.</p>
        <img src={correo} alt='animation' />
        <button className='button_form' onClick={() => setisSend(false)}>Crear uno nuevo</button>
      </div> */}
        </div>
      </div>

    </div>
  )
}

